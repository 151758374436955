<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ !pageDatas.examinationId ? '新增' :pageDatas.examState=='30'? '查看' : '编辑' }}考试</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="pageDatas" label-width="100px" :disabled="pageDatas.examState=='30'" class="demo-ruleForm form-box" size="small" label-position="top"
              :rules="pageDatasRules" :model="pageDatas">
              <el-row>
                    <el-col :span="11">
                      <el-form-item label="考试名称" prop="examinationName">
                        <el-input v-model="pageDatas.examinationName" clearable placeholder="请输入考试名称"></el-input>
                      </el-form-item>
                      <el-form-item label="考试类型" prop="examType">
                        <el-select v-model="pageDatas.examType" filterable clearable size="small" placeholder="请选择考试类型">
                          <el-option v-for="item in examTypeList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="培训工种" prop="occupationId" :required="isHaveTo">
                        <el-select v-model="pageDatas.occupationId" filterable remote clearable size="small"
                          :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                          <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                            :value="item.occupationId">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="培训等级" prop="levelCode" :required="isHaveTo">
                        <el-select v-model="pageDatas.levelCode" filterable clearable size="small" placeholder="请选择培训等级">
                          <el-option v-for="item in levelCodeList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="考试科目" prop="subjectCode">
                        <el-select v-model="pageDatas.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                          <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="考试时间" prop="testTime">
                        <el-date-picker v-model="pageDatas.testTime" type="datetimerange" start-placeholder="开始日期"
                          end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
                          value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                      </el-form-item>
                      <el-form-item label="考试地点" prop="address">
                        <el-input v-model="pageDatas.address" clearable></el-input>
                      </el-form-item>
                      <el-form-item label="考试封面：" prop="qrCode" class="form-item"><el-upload :on-change="handleAvatarSuccess5"
                          :before-upload="beforeAvatarUpload2" :http-request="$requestMine" :show-file-list="false"
                          class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg"><el-image
                            :src="pageDatas.imgUrl || require('@/assets/examination/examinationFm.png')" fit="contain"
                            class="imgCenter"></el-image>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">
                      &nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="考生须知" prop="notice">
                        <div ref="editor" id="noticeEditors" class="editor" style="width: 100%; max-width: 800px" />
                        <!-- <div v-else v-html="pageDatas.notice" /> -->
                      </el-form-item>
                    </el-col>
              </el-row>
              
              
            </el-form>
            <div v-if="pageDatas.examState!='30'" class="btn-box flexcc">
              <el-button class="bgc-bv" size="small" @click="cancelSave(2)">取 消</el-button>
              <el-button class="bgc-bv" size="small" @click="cancelSave(1)">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import E from "wangeditor";
let editor = {};
export default {
  name: "examination_onlineTest_onlineTestListAddEdit",
  components: {},
  data() {
    // 验证职业名称，等级的函数
    let validateName = (rule, value, callback) => {
      if (value === "" && this.isHaveTo) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      // 页面数据
      pageDatas: {
        examinationId: "", // 考试id
        examState: "", // 考试状态
        examinationName: "", // 考试名称
        examType: "", // 考试类型
        occupationId: "", // 培训工种
        levelCode: "", // 等级
        subjectCode: "", // 考试科目
        testTime: "", // 考试时间
        address: "", // 考试地点
        notice: "", // 考生须知
        imgKey: "", // 封面key
        imgUrl: "", // 封面url
      },
      // 页面数据 - 数据校验
      pageDatasRules: {
        examinationName: [
          { required: true, trigger: "blur", message: "请输入考试名称" },
        ],
        examType: [
          { required: true, trigger: "change", message: "请选择考试类型" },
        ],
        occupationId: [
          { validator: validateName, message: "请选择职业名称" }
        ],
        levelCode: [
          { validator: validateName, message: "请选择等级" }
        ],
        subjectCode: [
          { required: true, trigger: "change", message: "请选择考试科目" },
        ],
        testTime: [
          { required: true, trigger: "change", message: "请选择考试时间" },
        ],
        address: [
          { required: true, trigger: "blur", message: "请输入考试地点" },
        ],
        notice: [
          { required: true, trigger: "change", message: "请输入考生须知" },
        ],
      },
      // 考试类型 - 下拉数据
      examTypeList: [],
      // 培训工种 - 下拉数据
      occupation: [],
      // 等级 - 下拉数据
      levelCodeList: [],
      // 考试科目 - 下拉数据
      subjectCodeList: [],
    };
  },
  created() {
    this.dataDictionary();
    this.pageDatas.examinationId = this.$route.query?.examinationId ?? "";
    this.pageDatas.examState = this.$route.query?.examState ?? "";
    if (this.pageDatas.examinationId) {
        this.getOnlineTestInfo();
    }else{
        console.log(222)
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
    isHaveTo() {
      return this.pageDatas.examType == `10`;
    }
  },
  mounted() {
    this.editorInit();
    editor.txt.html(
        '1、考生应在考试前30分钟凭准考证和身份证(护照或军官证)入场，“双证”不齐全不得入场，入场后对号入座，并将证件放在桌面左侧。'+'<br/>' +
        '2、开考30分钟内考生不得退场,开考30分钟后迟到考生不得入场。'+'<br/>' +
        '3、考生不得将贵重物品带入考场,考场对考生丢失物品不负任何责任。'+'<br/>' +
        '4、参加上机考试的考生，应在计算机上正确填写个人信息和答案，并承担不正确填写信息的后果。'+'<br/>' +
        '5、参加纸笔作答的考生，只准带钢笔(碳素笔)、2B铅笔、橡皮等必要的文具入座,考生应在试卷、答题卡上正确填写个人信息和答案.并承担不正确使用答题纸(卡)和考试卷的后果。'+'<br/>' +
        '6、遵守考场规则,考试时不准旁窥、交谈、吸烟、传递资料信息或交换座位，严禁作弊。违者视情节轻重将终止其考试或取消考试成绩。'+'<br/>' +
        '7、考试过程中,不得要求监考人员解释试题。'+'<br/>' +
        '8、考试终止时间一到,立即停止答题。'+'<br/>' +
        '9、关闭手机等电子设备并放入手机袋内,随身携带或未关闭手机及电子设备的按违纪处理，取消考试成绩。'+'<br/>' +
        '10、考试结束后仍需妥善保管准考证,以备查询考试成绩信息时使用。'
    );
  },
  beforeDestroy(){
    editor.destroy();
    editor=null;
  },
  methods: {
    // 富文本
    editorInit() {
      // if(editor) return;
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };

      editor.config.onchange = (html) => {
        this.pageDatas.notice = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
    },
    // 获取 - 培训工种
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试类型
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
      // 等级
      const levelCodeList = this.$setDictionary(
        "ET_TRAIN_LEVEL",
        "list"
      );
      const list3 = [];
      for (const key in levelCodeList) {
        list3.push({
          value: key,
          label: levelCodeList[key],
        });
      }
      this.levelCodeList = list3;
      // 考试科目
      const examTypeList = this.$setDictionary(
        "EXAMINATION_EXAM_TYPE",
        "list"
      );
      const list2 = [];
      for (const key in examTypeList) {
        list2.push({
          value: key,
          label: examTypeList[key],
        });
      }
      this.examTypeList = list2;
    },
    // 获取 - 考试详情
    getOnlineTestInfo() {
      let url = "";
      // 运营
      if(['1','-1'].includes(this.userJson.roleId)){
        url = "/run/examination/getInfo";
      }
      // 机构
      if(['3','-3','-5','-7'].includes(this.userJson.roleId)){
        url = "/biz/examination/getInfo";
      }
      this.$post(url, { examinationId: this.pageDatas.examinationId }, 3000, true, 6)
        .then((res) => {
          this.pageDatas = {
            ...this.pageDatas,
            ...res.data
          }
          if(['1','-1'].includes(this.userJson.roleId)){
            this.pageDatas.examState = '30';
          }
          this.pageDatas.notice = editor.txt.html(this.pageDatas.notice);
          this.pageDatas.testTime = [res.data.startTime.replaceAll('/', '-'), res.data.endTime.replaceAll('/', '-')]
          this.getOccupation(res.data.occupationName)
        })
        .catch((err) => {
          return;
        });
    },
    // 上传封面图
    handleAvatarSuccess5(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "MATERIALS");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            console.log(res)
            this.pageDatas.imgKey = res.data.fileKey;
            this.pageDatas.imgUrl = res.data.fileURL;
          })
          .catch((err) => {
            console.log(err)
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 取消&保存
    cancelSave(cType) {
      if (cType == 1) {
        this.$refs['pageDatas'].validate((valid) => {
          if (valid) {
            let url = '';
            if (this.pageDatas.examinationId) {
              url = '/biz/examination/modify'
            } else {
              url = '/biz/examination/insert'
            }
            let cData = {
              ...this.pageDatas,
              imgKey: this.pageDatas.imgKey || undefined,
              imgUrl: this.pageDatas.imgKey || undefined,
            };
            if (cData.testTime.length) {
              cData.startTime = cData.testTime[0];
              cData.endTime = cData.testTime[1];
            }
            if (cData.notice && cData.notice.length > 2000) {
              this.$message.error("考生须知长度超长，请调整内容!");
              return;
            }
            this.$post(url, cData, 3000, true, 6)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.$router.back();
              })
              .catch((err) => {
                return;
              });
          }
        });
      } else {
        this.$router.back();
      }
    }
  },
};
</script>
<style lang="less" scoped>
.form-box {
  width: 100%;
  margin: auto;

  .notice {
    /deep/ textarea {
      min-height: 150px !important;

  // .el-form-item {
  //   .el-input,.el-select {
  //     width: 300px;
  //   }
  //   .el-date-editor {
  //     width: 400px !important;
    }
  }
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
</style>
<style>
#noticeEditors .w-e-text-container {
  height: auto !important;
  min-height: 600px;
  font-family: "SimSun" !important;
}
#noticeEditors .w-e-text {
  height: auto !important;
  min-height: 600px;
  font-family: "SimSun" !important;
}
</style>
  